@import url(../../vendor/Railroad/stylesheet.css);

@keyframes fade3 {

  0% {
    opacity: 0.2;
  }

  10% {
    opacity: 0.9;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0.9;
  }
}

.game__content {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  max-height: 800px;
  /*max-height: 900px;*/
  position: relative;
  background: #1a1919;
  overflow: hidden;
}

@media screen and (max-width: 1440px) {

  .game__content {
    height: 60vw;
  }
}

.game__pic {
  object-fit: cover;
  object-position: right bottom;
  /*animation: fade3 1 9s;*/
  width: 1400px;
  height: 900px;
}

@media screen and (max-width: 1480px) {

  .game__pic {
    width: calc(100vw - 20px);
    height: calc(66vw - 40px);
  }
}

.game__saveText {
  font-size: clamp(8px, 2vw, 24px);
  color: #e3ded5;
  align-self: center;
  margin: 0;
  text-align: center;
}

.game__fone {
  object-fit: cover;
  object-position: right bottom;
  width: 1400px;
  height: 800px;
  border-radius: 20px;
  z-index: 0;
  position: absolute;
  opacity: 0.1;
  border: 1px solid gray;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

@media screen and (max-width: 1480px) {

  .game__fone {
    width: calc(100vw - 20px);
    height: calc(66vw - 40px);
  }
}

.game__figure {
  height: 740px;
  z-index: 1;
  position: absolute;
  left: 90px;
  bottom: 20px;
}

@media screen and (max-width: 1480px) {

  .game__figure {
    height: calc(56vw);
    bottom: 0;
    top: 90px;
  }
}

.game__figure2 {
  height: 700px;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 90px;
}

@media screen and (max-width: 1480px) {

  .game__figure2 {
    height: calc(56vw);
    bottom: 0;
    top: 90px;
  }
}

.game__figure3 {
  height: 740px;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 290px;
}

@media screen and (max-width: 1480px) {

  .game__figure3 {
    height: calc(56vw);
    bottom: 0;
    top: 90px;
  }
}

@media screen and (max-width: 880px) {

  .game__figure3 {
    height: calc(44vw);
    bottom: 0;
    top: none;
    right: 40px;
  }
}

.game__figure4 {
  height: 670px;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 90px;
}

@media screen and (max-width: 1480px) {

  .game__figure4 {
    height: calc(47vw);
    bottom: 0;
    /*top: 90px;*/
  }
}
