@import url(../../vendor/Railroad/stylesheet.css);

@keyframes ani {

  0% {
    transform: scale(1.1);
  }

  100% {
    /*opacity: 0.7;*/
    transform: scale(1.6);
  }
}

@keyframes fade {

  0% {
    opacity: 0.2;
    transform: scale(1) translateX(0) translateY(0);
  }

  15% {
    opacity: 0.9;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
    transform: scale(1.3) translateX(40px) translateY(-40px);
  }
}

@keyframes unfade {

  0% {
    opacity: 0.2;
    transform: scale(1.3) translateX(50px);;
  }

  15% {
    opacity: 0.9;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
    transform: scale(1) translateX(0);;
  }
}

.main__content {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  max-height: 900px;
  position: relative;
  background: #1a1919;
  overflow: hidden;
}

.main__pic {
  object-fit: cover;
  object-position: right bottom;
  width: 1400px;
  height: 900px;
  z-index: 1;
}

.main__pic1 {
  animation:
    fade linear 11s;
}

.main__pic2 {
  animation:
    unfade linear 11s;
}

@media screen and (max-width: 1480px) {

  .main__pic {
    width: calc(100vw - 40px);
    height: calc(66vw - 40px);
  }
}

@media screen and (max-width: 580px) {

  .main__pic {
    width: 100vw;
    height: 66vw;
  }
}

.main__fone {
  object-fit: cover;
  object-position: right bottom;
  width: 1400px;
  height: 900px;
  border-radius: 20px;
  z-index: 0;
  position: absolute;
  opacity: 0.1;
  border: 1px solid gray;
}

@media screen and (max-width: 1480px) {

  .main__fone {
    width: calc(100vw - 40px);
    height: calc(66vw - 40px);
  }
}

@media screen and (max-width: 580px) {

  .main__fone {
    width: 100vw;
    height: 66vw;
  }
}

.main__fact-block {
  position: absolute;
  top: 120px;
  right: -40px;
  z-index: 1;
  color: white;
  background: rgb(51 50 50);
  opacity: 0.6;
  border-radius: 15px 0 15px 15px;
  padding: 20px;
  animation: ani 1 2s ease;
  width: 28vw;
  min-height: 145px;
  margin: 0;
  font-size: clamp(8px, 1.8vw, 22px);
  box-sizing: border-box;
}

@media screen and (max-width: 880px) {

  .main__fact-block {
    top: 100px;
    min-height: 112px;
  }
}

@media screen and (max-width: 760px) {

  .main__fact-block {
    top: 80px;
    padding: 26px 8px 8px;
  }
}

@media screen and (max-width: 705px) {

  .main__fact-block {
    top: 70px;
    padding: 12px 8px 8px;
    width: 34%;
  }
}

@media screen and (max-width: 560px) {

  .main__fact-block {
    top: 60px;
  }
}

@media screen and (max-width: 480px) {

  .main__fact-block {
    top: 48px;
  }
}

.main__fact-block p {
  color: rgb(251 237 158);
  margin: 10px 0 0;
  padding-right: 40px;
  box-sizing: border-box;
}

.main__button-block {
  border-radius: 20px 0 0 20px;
  position: absolute;
  top: 00;
  left: 20px;
  /*opacity: 0.8;*/
  font-size: 50px;
  z-index: 2;
  width: 32%;
  padding: 188px 32px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  padding-top: 188px;
  box-sizing: border-box;
  gap: 60px;
}

@media screen and (max-width: 1080px) {

  .main__button-block {
    gap: 4vw;
    padding: 16.6vw 26px 0;
  }
}

@media screen and (max-width: 760px) {

  .main__button-block {
    padding: 16.6vw 16px 0;
    left: 8px;
  }
}

@media screen and (max-width: 540px) {

  .main__button-block {
    padding: 17.6vw 12px 0;
    left: 0;
  }
}

.main__button-block::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  z-index: -1;
}

.button {
  padding: 20px 40px;
  font-size: clamp(12px, 2vw, 40px);
  position: relative;
  background: transparent;
  color: #ffa500;
  text-transform: uppercase;
  border: 2px solid #ffa500;
  cursor: pointer;
  transition: all 0.7s;
  overflow: hidden;
  border-radius: 100px;
  box-shadow: 0 10px 10px black;
  height: auto;
}

@media screen and (max-width: 1000px) {

  .button {
    padding: 18px 20px;
  }
}

@media screen and (max-width: 740px) {

  .button {
    padding: 12px 18px;
  }
}

@media screen and (max-width: 580px) {

  .button {
    padding: 4px;
    font-size: clamp(8px, 2vw, 40px);
    border: 1px solid #ffa500;
  }
}

.button:hover {
  color: #000;
  box-shadow: 0 0 0 black;
  transform: translateY(4px);
}

.span {
  transition: all 0.7s;
  z-index: -1;
}

.button .main__button-first {
  content: '';
  position: absolute;
  right: 100%;
  top: 0;
  width: 25%;
  height: 100%;
  background: #ffa500;
}

.button:hover .main__button-first {
  top: 0;
  right: 0;
}

.button .main__button-second {
  content: '';
  position: absolute;
  left: 25%;
  top: -100%;
  height: 100%;
  width: 25%;
  background: #ffa500;
}

.button:hover .main__button-second {
  top: 0;
  left: 50%;
}

.button .main__button-third {
  content: '';
  position: absolute;
  left: 50%;
  height: 100%;
  top: 100%;
  width: 25%;
  background: #ffa500;
}

.button:hover .main__button-third {
  top: 0;
  left: 25%;
}

.button .main__button-fourth {
  content: '';
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 25%;
  background: #ffa500;
}

.button:hover .main__button-fourth {
  top: 0;
  left: 0;
}

.main__greetingText {
  color: #91843d;
  font-size: clamp(10px, 2vw, 26px);
  margin: 0;
}
