@import url(../../vendor/Railroad/stylesheet.css);

.pageNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pageNotFound__title {
  font-family: Inter;
  font-weight: 400;
  font-size: 140px;
  line-height: 169px;
  text-align: center;
  margin: 0;
  padding-top: 100px;
}

.pageNotFound__text {
  font-family: Inter;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin: 0;
  padding-top: 5px;
}

.pageNotFound__text:first-of-type {
  padding-bottom: 50px;
}

.pageNotFound__link {
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin: 0;
  text-decoration: none;
  padding-top: 84px;
}

.pageNotFound__link:hover {
  opacity: 0.5;
  cursor: pointer;
  transition: 1s;
}

@media screen and (max-width: 770px) {

  .pageNotFound__title {
    padding-top: 100px;
  }
}

@media screen and (max-width: 550px) {

  .pageNotFound__title {
    font-size: 80px;
    line-height: 97px;
    padding-top: 100px;
  }
}

@media screen and (max-width: 550px) {

  .pageNotFound__text {
    font-size: 12px;
    line-height: 15px;
  }
}

@media screen and (max-width: 550px) {

  .pageNotFound__link {
    padding-top: 84px;
  }
}
