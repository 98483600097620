@import url(../../vendor/Railroad/stylesheet.css);

.resumeGameScreen__content {
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  /*max-height: 900px;*/
  position: relative;
  background: #1a1919;
  padding-top: 170px;
  color: rgb(100 100 98);
  min-height: 780px;
}

@media screen and (max-width: 1100px) {

  .resumeGameScreen__content {
    flex-direction: column;
  }
}

@media screen and (max-width: 620px) {

  .resumeGameScreen__content {
    padding-top: 100px;
  }
}

.resumeGameScreen__textblock-content {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  /*max-height: 900px;*/
  position: relative;
  background: #1a1919;
  /*padding-top: 170px;*/
  color: rgb(100 100 98);
  min-height: 780px;
}

@media screen and (max-width: 1100px) {

  .resumeGameScreen__textblock-content {
    min-height: auto;
    align-items: center;
  }
}

@media screen and (max-width: 550px) {

  .resumeGameScreen__textblock-content {
    padding: 0 12px;
  }
}

.resumeGameScreen__pic {
  height: 700px;
  margin: 20px 50px;
  z-index: 2;
}

@media screen and (max-width: 1100px) {

  .resumeGameScreen__pic {
    display: none;
  }
}

.resumeGameScreen__title {
  font-size: 28px;
  margin-bottom: 8px;
}

@media screen and (max-width: 550px) {

  .resumeGameScreen__title {
    font-size: 26px;
    margin-bottom: 8px;
  }
}

.resumeGameScreen__last-text {
  font-size: 52px;
  margin-bottom: 20px;
  margin-top: 150px;
  width: 480px;
}

@media screen and (max-width: 550px) {

  .resumeGameScreen__last-text {
    font-size: 28x;
    margin-top: 50px;
    text-align: center;
  }
}

.resumeGameScreen__text {
  font-size: 26px;
  margin: 0;
  max-width: 480px;
}

@media screen and (max-width: 550px) {

  .resumeGameScreen__text {
    font-size: 22px;
  }
}

.resumeGameScreen__small-text {
  font-size: 14px;
  margin: 0;
  text-align: center;
}

.resumeGameScreen__small-text:last-of-type {
  margin-bottom: 10px;
}

.resumeGameScreen__form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.resumeGameScreen__button {
  color: rgb(51 50 50);
  background-color: #ffa500;
  max-width: 480px;
  width: 100%;
  height: 60px;
  margin: 20px 0;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 600;
}

.resumeGameScreen__button:hover {
  opacity: 0.8;
  cursor: pointer;
  transition: 0.5s;
}

.resumeGameScreen__button-block {
  width: 480px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 20px;
}

@media screen and (max-width: 560px) {

  .resumeGameScreen__button-block {
    flex-direction: column;
    align-items: center;
  }
}

.resumeGameScreen__small-button {
  color: rgb(51 50 50);
  background-color: #ffa500;
  width: 230px;
  height: 72px;
  margin: 12px 0;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 600;
}

.resumeGameScreen__small-button:hover {
  opacity: 0.8;
  cursor: pointer;
  transition: 0.5s;
}

.resumeGameScreen__input {
  background: white;
  border: none;
  border-bottom: 1px solid #e8e8e8;
  color: #000;
  font-family: Inter, sans-serif;
  font-weight: 400;
  line-height: 17px;
  max-width: 480px;
  width: 100%;
  height: 52px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  padding-left: 20px;
  border-radius: 10px;
  font-size: 20px;
  margin-top: 12px;
}
