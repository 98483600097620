@import url(../../vendor/Railroad/stylesheet.css);

@keyframes fade {

  0% {
    opacity: 0.2;
    transform: scale(1) translateX(0) translateY(0);
  }

  15% {
    opacity: 0.9;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
    transform: scale(1.3) translateX(40px) translateY(-40px);
  }
}

@keyframes unfade {

  0% {
    opacity: 0.2;
    transform: scale(1.3) translateX(50px);;
  }

  15% {
    opacity: 0.9;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
    transform: scale(1) translateX(0);;
  }
}

.prologue__content {
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  max-height: 900px;
  position: relative;
  background: #1a1919;
  overflow: hidden;
}

.prologue__skip {
  height: 40px;
  width: 50px;
  position: absolute;
  bottom: 40px;
  right: 32px;
  color: white;
  z-index: 3;
}

@media screen and (max-width: 680px) {

  .prologue__skip {
    height: 20px;
    width: 26px;
  }
}

.prologue__skip:hover {
  cursor: pointer;
  opacity: 0.6;
  transition: 0.5s ease;
}

.prologue__text {
  font-size: 32px;
  color: #fff;
  padding-left: 30px;
  position: absolute;
  width: 600px;
  top: 460px;
  opacity: 0.8;
  font-family: 'Courier New', Courier, monospace;
}

@media screen and (max-width: 1280px) {

  .prologue__text {
    top: 40vw;
    width: 40%;
    font-size: clamp(12px, 2vw, 32px);
  }
}

.prologue__pic {
  object-fit: cover;
  object-position: right bottom;
  width: 1400px;
  height: 900px;
}

@media screen and (max-width: 580px) {

  .prologue__pic {
    width: 100vw;
    height: 66vw;
  }
}

@media screen and (max-width: 1480px) {

  .prologue__pic {
    width: calc(100vw - 40px);
    height: calc(66vw - 40px);
  }
}

@media screen and (max-width: 580px) {

  .prologue__fone {
    width: 100vw;
    height: 66vw;
  }
}

.prologue__pic1 {
  animation:
    fade linear 6.4s;
}

.prologue__pic2 {
  animation:
    unfade linear 6.4s;
}

.prologue__fone {
  object-fit: cover;
  object-position: right bottom;
  width: 1400px;
  height: 900px;
  border-radius: 20px;
  z-index: 0;
  position: absolute;
  opacity: 0.1;
  border: 1px solid gray;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

@media screen and (max-width: 1480px) {

  .prologue__fone {
    width: calc(100vw - 40px);
    height: calc(66vw - 40px);
  }
}
